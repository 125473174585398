import React, { useState } from 'react'
import loanVideo from "../assets/loanVideo.mp4"
import logo from "../assets/logoweb.webp"
import { AiFillHome } from 'react-icons/ai'
import axios from 'axios';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import * as Yup from 'yup'
import { ErrorMessage, Field, Form, Formik } from 'formik';

const ResetPassword = () => {
  const navigate = useNavigate()
  const goToHome = () => {
    navigate('/')
  }

  const initValues= {
    email : "",
  }

  const [loading, setLoading] = useState(false)

  const gameOver = useSpring({
    from:{ opacity: 0, marginTop: -1000 },
    to:{ opacity: 1, marginTop: 0 }
  })
  

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter your email!"),
  })

  const onSubmit = (data, { resetForm }) => {
     setLoading(true)

     axios.post(`https://loan-app-server.onrender.com/auth/user/reset/password`, data)
      .then(res => {
        setLoading(false)
        if(res.data.error){
          swal({
            title: "Oops !",
            text: res.data.error,
            icon: "error",
            button: true,
          })
        }else if(res.data.message){
          resetForm()
          swal({
            title: "Email Sent",
            text: res.data.message,
            icon: "success",
            button: true,
          })
        }else{
          resetForm()
          setLoading(false)
          swal({
            title: "Oops !",
            text: JSON.stringify(res.data),
            icon: "success",
            button: true,
          })
        }
      })
    
  }


  return (
    <div className="flex flex-start items-center flex-col h-screen font-poppins">
      <div className='relative h-full w-full'>
        <video
            src={loanVideo}
            type="video/mp4"
            className='h-full object-cover w-full '
            autoPlay={true}
            loop
            controls={false}
            muted
        />
      </div>
      <div className='absolute left-0 top-0 p-[12px] rounded-full bg-green-700 m-1 animate-pulse cursor-pointer z-10' onClick={goToHome}>
        <AiFillHome fontSize={20} className='text-white' />
      </div>
      <div className="absolute left-0 right-0 flex flex-col py-4 items-center bg-black bg-opacity-40 w-full h-screen gap-y-[12px]">
        <div className="p-5">
          <img src={logo} alt="logo" width={200}/>
        </div>

        <animated.div style={gameOver} className='mt-[10px] w-full flex items-center justify-center font-poppins'>
            <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >

                {({ errors, touched, dirty, isValid }) => (
                    <Form className='flex flex-col gap-3 shadow-xl w-full max-w-[365px] py-6  px-2 border-y-2 border-y-green-500 rounded-2xl'>
                        <div className='gap-1 flex flex-col items-start p-2' >
                            <label htmlFor=""
                                className="text-lg px-2 font-semibold font-poppins text-white"
                            >
                                Email Address* :
                            </label>
                            <div className='flex w-full animate-pulse flex-row items-end text-red-900 font-medium justify-end px-2'>
                                <ErrorMessage name='email'/>
                            </div>
                            <Field
                                type="email"
                                id="email" 
                                name="email"
                                className={touched.email && errors.email ? 
                                "w-full p-3 rounded-lg px-5 text-red-800 border-2 border-red-700" :
                                "w-full p-3 rounded-lg px-5 text-black"}
                                autoComplete="off"
                            />
                        </div>

                        <hr />

                        <div className='w-full flex flex-col items-end justify-center'>
                            <button 
                                type='submit'
                                className={
                                !(dirty && isValid) ? 
                                "border-0 p-2 bg-white bg-opacity-80 text-gray-400 rounded-xl px-5"
                                : 'border-0 p-3 bg-white bg-opacity-60 text-green-900 rounded-xl px-5'
                                }>

                                {
                                    loading ? 'Please Wait...' : 'Reset'
                                }
                            
                            </button>
                        </div> 
                    </Form>
                )}
                
            </Formik> 
        </animated.div>

        <div className='text-white font-bold'>
          <Link to={'/login'}>Back to Login </Link>
        </div>
          
      </div>

    </div>
  )
}

export default ResetPassword
