import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { Routes, Route} from "react-router-dom";
import { Login, Register, AddProfile, ResetPasswordForm} from './components'
import { AuthContext } from './helpers/Context';
import Home from './containers/Home';
import EmailVerify from './containers/EmailVerify';
import ResetPassword from './components/ResetPassword';

const App = () => {

  const [authUser, setAuthUser] = useState({
    googleId: 0,
    name: "",
    imageUrl: "",
    email: "",
    role: "",
    login_status: false
  })

  const [toggleUserInfo, setToggleUserInfo] = useState(false)

  useEffect(() => {
    axios.get("https://loan-app-server.onrender.com/auth/verify", {
      headers: {
        loginToken: localStorage.getItem("loginToken")
      }
    }).then(res => {
      setAuthUser(authUser => ({...authUser, 
        googleId: res.data.user.googleId,
        name: res.data.user.name,
        imageUrl: res.data.user.imageUrl,
        email: res.data.user.email,
        role: res.data.user.role,
        login_status: res.data.login_status 
      }))
    }).catch(err => console.log("verification_error", err))
  }, [])

  return (
    <AuthContext.Provider value = {{authUser, setAuthUser, toggleUserInfo, setToggleUserInfo }}>
        <Routes>
          <Route path="/login" element={<Login/>} exact/>
          <Route path="/register" element={<Register/>} exact/>
          <Route path="/resetPassword" element={<ResetPassword/>} exact/>
          <Route path='/createProfile' element={<AddProfile/>}/>
          <Route path='/emailUser/:userId/verify/:token' element={<EmailVerify/>}/>
          <Route path='/emailUser/:userId/reset/password/:token' element={<ResetPasswordForm/>}/>
          <Route path="/*" element={<Home/>}/>
        </Routes>
    </AuthContext.Provider>
    
   
  )
}

export default App
