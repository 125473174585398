import React from 'react'
import { AiOutlinePhone, } from 'react-icons/ai'
import { CiLocationOn, } from 'react-icons/ci'
import { MdOutlineMarkEmailRead, } from 'react-icons/md'

const ContactUs = () => {
  return (
    <div className='flex flex-col items-center'>
        <h1 className='p-[12px] text-[30px] font-semibold font-poppins animate-pulse'>GET IN TOUCH</h1>
        <div className='flex flex-wrap sm:justify-start md:justify-evenly justify-center w-full feedback-container relative z-[1] py-[10px]'>
            <div className='flex flex-col justify-start px-10 py-12 rounded-[20px] max-w-[340px] md:mr-6 sm:mr-2 mr-0 feedback-card items-center'>
                <button className='p-4 rounded-full bg-black'>
                    <AiOutlinePhone fontSize={46}/>
                </button>
                
                <button className='font-poppins font-normal text-[16px] leading-[20px] text-white my-10 cursor-pointer'>
                   +265 996 570 252
                </button>
                <button className='font-poppins font-normal text-[16px] leading-[20px] text-white my-10 cursor-pointer'>
                   +265 884 799 203
                </button>
            </div>
           <div className='flex flex-col justify-between px-10 py-12 rounded-[20px] max-w-[340px] md:mr-6 sm:mr-2 mr-0 feedback-card items-center'>    
                <button className='p-4 rounded-full bg-black'>
                    <CiLocationOn fontSize={46}/>
                </button>
                <p className='font-poppins font-normal text-[18px] leading-[32px] text-white my-10'>
                  Lilongwe, Malawi
                </p>
            </div>
            <div className='flex flex-col justify-between px-10 py-12 rounded-[20px] max-w-[340px] md:mr-6 sm:mr-2 mr-0 feedback-card items-center'>
                <button className='p-4 rounded-full bg-black'>
                    <MdOutlineMarkEmailRead fontSize={46}/>
                </button>
                
                <button className='font-poppins font-normal text-[16px] leading-[20px] text-white my-10 cursor-pointer'>
                   mikelibamba@gmail.com
                </button>
                <button className='font-poppins font-normal text-[16px] leading-[20px] text-white my-10 cursor-pointer'>
                   bis16-mlibamba@poly.ac.mw
                </button>
            </div>
        </div>
    </div>
     
  )
}

export default ContactUs
