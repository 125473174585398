import React, { useContext } from 'react'
import { AiOutlineEdit } from 'react-icons/ai'
import { AuthContext } from '../../helpers/Context'

const BasicInfo = ( { data, modal } ) => {
  const { authUser } = useContext(AuthContext)
  return (
    <div className='flex flex-row p-2 shadow-sm gap-x-2 justify-center items-baseline'>
      <img src={authUser.imageUrl} alt="Profile IMG" className='w-40 rounded-full m-2' />
      <div>
        <p className='font-bold text-2xl'>{data.basicInfo.fullName}</p>
        <p className='text-lg italic'>{data.basicInfo.gender}</p>
      </div>
      <AiOutlineEdit 
        fontSize={20} 
        className='text-white cursor-pointer ' 
        title='Edit'
        onClick={modal}
      />
    </div>
  )
}

export default BasicInfo
