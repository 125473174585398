import React from 'react'
import { useSpring, animated } from 'react-spring';
import styles from '../styles';
import quotes from '../assets/quotes.svg'

const Rates = () => {
  //slide animation 
  const slide = useSpring({
    from: { transform: 'translateX(100%)' },
    to: { transform: 'translateX(0%)' }
  });

  const adminImage = "https://lh3.googleusercontent.com/a/AAcHTteOVb2uGMIPFQNKeepWyO9h9oF-9eDkfzYtmvFbqQ=s96-c";

  const rates = [
    {
        "id": 50,
        "value": "4 Weeks"
    },
    {
        "id": 40,
        "value": "3 Weeks"
    },
    {
        "id": 30,
        "value": "2 Weeks"
    },
    {
        "id": 20,
        "value": "1 Week"
    },

  ]

  return (
    <animated.section style={slide} className={`${styles.flexCenter} flex-row flex-wrap sm:mb-20 mb-5`}>
        {
            rates.map(rate => (
                <div key={rate.id} className={`flex-1 flex flex-row justify-start items-center m-3`}>
                    <h4 className={`font-poppins font-semibold xs:text-[30px] text-[25px] xs:leading-[53px] leading-[43px] text-white`}>
                        { rate.id }%
                    </h4>
                    <p className={`font-poppins font-semibold xs:text-[20px] text-[15px] xs:leading-[25px] leading-[19px] text-gradient uppercase ml-2`}>
                        { rate.value }
                    </p>
                </div>
            ))
        }

        <div className='flex flex-wrap justify-evenly w-full feedback-container relative z-[1] py-[10px]'>
            <div className='flex flex-col justify-between px-10 py-12 rounded-[20px] max-w-[340px] md:mr-12 sm:mr-6 mr-0 feedback-card'>
                <img src={quotes} alt="Quotes" className='w-[34px] h-[22px] object-contain' />
                <p className='font-poppins font-normal text-[18px] leading-[32px] text-white my-10'>
                    Get the funds you need for life's unexpected expenses with our easy and fast online loan application process.
                </p>
                <div className='flex flex-row'>
                    <img src={adminImage} alt="IMG" className='w-[56px] h-[56px] rounded-full'/>
                    <div className='flex flex-col ml-4'>
                        <h4 className='font-poppins font-semibold text-[18px] leading-[32px] text-white'>
                           Mike C. Mhango  
                        </h4>
                        <p className='font-poppins font-semibold text-[16px] leading-[32px] text-white'>
                           CEO  
                        </p>
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-between px-10 py-12 rounded-[20px] max-w-[340px] md:mr-6 sm:mr-2 mr-0 feedback-card'>
                <img src={quotes} alt="Quotes" className='w-[34px] h-[22px] object-contain' />
                <p className='font-poppins font-normal text-[18px] leading-[32px] text-white my-10'>
                    At our lending platform, we believe that obtaining a loan should be simple, transparent, and hassle-free.
                </p>
                 <div className='flex flex-row'>
                    <img src={adminImage} alt="IMG" className='w-[56px] h-[56px] rounded-full'/>
                    <div className='flex flex-col ml-4'>
                        <h4 className='font-poppins font-semibold text-[18px] leading-[32px] text-white'>
                           Mike C. Mhango  
                        </h4>
                        <p className='font-poppins font-semibold text-[16px] leading-[32px] text-white'>
                           CEO  
                        </p>
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-between px-10 py-12 rounded-[20px] max-w-[340px] md:mr-6 sm:mr-2 mr-0 feedback-card'>
                <img src={quotes} alt="Quotes" className='w-[34px] h-[22px] object-contain' />
                <p className='font-poppins font-normal text-[18px] leading-[32px] text-white my-10'>
                    Apply for a loan with us today and get access to funds in as little as one business day.
                </p>
                 <div className='flex flex-row'>
                    <img src={adminImage} alt="IMG" className='w-[56px] h-[56px] rounded-full'/>
                    <div className='flex flex-col ml-4'>
                        <h4 className='font-poppins font-semibold text-[18px] leading-[32px] text-white'>
                           Mike C. Mhango  
                        </h4>
                        <p className='font-poppins font-semibold text-[16px] leading-[32px] text-white'>
                           CEO  
                        </p>
                    </div>
                </div>
            </div>
        </div> 
    </animated.section>
  )
}

export default Rates
