import React from 'react'
import styles, {layout} from '../styles'

const PendingApplications = () => {
  return (
    <section className={layout.section}>
      Pending Applications
    </section>
  )
}

export default PendingApplications
