import React from 'react'
import { animated, useSpring } from 'react-spring';
import loanPic1 from "../assets/loanpic1.jpg"
import { AiFillMoneyCollect, } from 'react-icons/ai'
import styles from '../styles';
import { GetStarted } from './';

const Intro = () => {
  const delay3 = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 2500
  });

  const gameOver = useSpring({
    from:{ opacity: 0, marginTop: -1000 },
    to:{ opacity: 1, marginTop: 0 }
  })

  return (
    <animated.section style={gameOver}
      className={`w-full h-full flex md:flex-row flex-col ${styles.paddingY}`}
    >
      <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-10 p-3`}>
        <animated.div style={delay3} className={`flex flex-row items-center justify-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2`}>
           <AiFillMoneyCollect fontSize={20}/>
           <p className={`ml-2 ${styles.paragraph}`}>
              <span className='text-gradient'>Get a loan up to 20% interest</span> for 1 week
           </p>
        </animated.div>

        <div className='flex flex-row items-center justify-between w-full mt-3'>
          <h1 className={`flex-1 font-poppins font-semibold leading-[50px] ss:leading-[70px] ss:font-[66px] text-[46px] text-white`}>
            The Quick <br className='sm:block hidden'/>{' '}
            <span className='text-gradient'>Loan</span> {' '}
          </h1>

          <div className='ss:flex hidden md:mr-4 mr-0'>
            <GetStarted />
          </div>
        </div>

        <h1 className={`w-full font-poppins font-semibold leading-[60px] ss:leading-[80px] ss:font-[60px] text-[46px] text-white`}>
          Provider
        </h1>

        <p className={`${styles.paragraph} max-w-[470px] px-[9px] mt-5 text-justify`}>
          We provide quick and easy loans to help you meet your financial
          needs, with competitive rates and flexible terms that fit your budget.
        </p>
      </div>

      <div className={`flex-1 flex md:w-1/2 w-[100%] ${styles.flexCenter} md:my-0 my-6 relative `}>
        <img src={loanPic1} alt="LoanImage" className='w-[100%] h-[100%] relative z-[5]' />
        <div className='absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient'></div>
        <div className='absolute z-[1] w-[80%] h-[85%] rounded-full bottom-40 white__gradient'></div>
        <div className='absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient'></div>
      </div>

      <div className={`ss:hidden ${styles.flexCenter}`}>
        <GetStarted/>
      </div>
    </animated.section>
  )
}

export default Intro
