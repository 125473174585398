import React from 'react'
import {layout} from '../styles'

const MyLoans = () => {
  return (
    <section className={layout.section}>
      My Loans
    </section>
  )
}

export default MyLoans
