import React from 'react'
import { About, ContactUs, Intro, Rates } from '../components';


const Welcome = () => {
  return (
    <div className='flex flex-1 flex-col w-full h-full'>
      <Intro />
      <Rates />
      <About />
      <ContactUs />
    </div>
  )
}

export default Welcome
