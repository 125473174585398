import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from '../../helpers/Context'
import { useNavigate } from 'react-router-dom'
import { COLUMNS } from '../tables/userstable/columns'
import UserTable from '../tables/userstable/UserTable'
import axios from 'axios'
import swal from 'sweetalert'
import {FaArrowLeft} from 'react-icons/fa'

const LoanUsers = () => {

  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const { authUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const columns = useMemo(() => COLUMNS, [])

  useEffect(() => {
    if(authUser.googleId !== 0){
      !authUser.login_status && navigate("/")
    }else{
      if(!localStorage.getItem('loginToken')){
        navigate("/")
      }
    }
  }, [authUser])

  useEffect(() => {
    setLoading(true)
    axios.get("https://loan-app-server.onrender.com/auth", {
      headers: { 
        loginToken : localStorage.getItem("loginToken") 
      }
    }).then(res => {
      setLoading(false)
      setUsers(res.data)
    }).catch(err => {
      setLoading(false)
      swal({
        icon: "error",
        title: "Oops!",
        text: err.response ? err.response.data.error : err.message
      })
    })
  }, [])

  return (
    <section className={ `w-full`}>
      <h1 className='text-center text-xl font-light p-4 animate-bounce'>Registered Users</h1>
      <hr />
      <div className='py-2 cursor-pointer' onClick={() => navigate(-1)}>
        <FaArrowLeft fontSize={22}/>
      </div>

      {
        loading ? <p className='w-full h-full text-center text-lg py-5'>Getting Users ... Please wait</p> :
          users.error ? (
              <div className='w-full text-center text-lg py-5 gap-y-10 flex flex-col justify-center items-center'>
                <p>{users.error}</p>
              </div>              
            ) :
            <UserTable columns={columns} data={users} />
      }
    </section>
  )
}

export default LoanUsers
