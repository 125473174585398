import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import ColumnFilter from '../ColumnFilter'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import GlobalFilter from '../GlobalFilter'

const UserTable = ({columns , data}) => {
  const navigate = useNavigate()

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter
    }
  },[])

  const initialState = { hiddenColumns: ['imageUrl', 'googleId'] };

  const tableHooks = hooks => {
    hooks.visibleColumns.push(columns => [
      ...columns, {
        Header: "Actions",
        Footer: "Actions",
        accessor: "actions",
        disableFilters: true,
        Cell: ({row}) => (
          <button
            className='p-2 border-2 rounded-xl bg-black-gradient'
            onClick={() => takeMeToUserDetails(row.values)}
          >
            Explore
          </button>
        ),
      }
    ])
  }

  const takeMeToUserDetails = rowData => {
    const serializedObj = encodeURIComponent(JSON.stringify(rowData))
    navigate(`/user/userInfo/${serializedObj}`)
  } 

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    setGlobalFilter,
    state,
  } = useTable({ columns, data, defaultColumn, initialState }, useFilters, useGlobalFilter, useSortBy, tableHooks, usePagination)

  const { globalFilter, pageSize, pageIndex } = state

  useEffect(() => {
    setPageSize(3)
  }, [])

  return (
    <section>
      {/* On mobile */}
      <div className='w-full md:hidden flex flex-col justify-center overflow-auto'> 
        {
          data.map( (row, index) => {
            return (
               <div key={index} className="w-full my-5 p-6 bg-black-gradient border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className='flex flex-1 items-center justify-between'>
                    <p className=' text-xl font-bold py-2'>
                      Name: {row.name}
                    </p>
                     <p className='text-lg py-2 mr-4'>
                      Role: {row.role}
                    </p>
                  </div>

                  <div className='border-r-2 border-gray-300'>
                      <p className='text-lg py-2 mr-4'>
                        Email: {row.email}
                      </p>
                      <div className='flex flex-1 justify-between'>
                        <div>
                          <h5 className="mb-1 mt-2  text-lg font-semibold tracking-tight text-gray-300- dark:text-white">Verified ?</h5>
                          <p className="mb-5 font-normal text-gray-400">{row.verified ? "Yes" : "No"}</p>
                          <p className='text-lg py-1'>
                            Status: {row.status ? "Active" : "Inactive"}
                          </p>
                        </div>

                        <div className="flex items-center justify-end flex-row p-1">
                          <img src={row.imageUrl} alt="ProfileImage" className='w-20 h-20 rounded-full'/>
                        </div>
                      </div>
                  </div>
                  <div 
                    className="inline-flex items-center text-blue-600 hover:underline py-2 cursor-pointer text-lg mb-3"
                    onClick={() => takeMeToUserDetails(row)}
                  >
                      Explore
                      <svg className='w-5 ml-3' fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z">
                        </path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
                      </svg>
                  </div>
                </div>
            )
          })
        }

      </div>

      {/* On desktop */}
      <section className='w-full md:flex hidden items-center justify-center flex-col'>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} placeholderText={"Search Users"}/>

        <table {...getTableProps() } className="min-w-full text-left font-light overflow-auto">
          <thead>
            {
              headerGroups.map(headerGroup => 
                <tr { ...headerGroup.getHeaderGroupProps() }>
                  {
                    headerGroup.headers.map(col => 
                      <th { ...col.getHeaderProps(col.getSortByToggleProps()) } className='px-6 py-4'>
                        { col.render('Header') }
                        <span>
                          {col.isSorted ? (col.isSortedDesc ? ' ▼' : ' ▲') :''}
                        </span>
                        <div>
                          {
                            col.canFilter ? col.render('Filter') : null
                          }
                        </div>
                      </th>
                    )
                  }
                </tr>
              )
            }
          </thead>

          <tbody { ...getTableBodyProps } >
            {
              page.map(row => {
                prepareRow(row)

                return (
                  <tr 
                    { ...row.getRowProps()}
                    className='border-b transition duration-300 ease-in-out hover:bg-black'
                  >
                    {
                      row.cells.map( cell => 
                        <td { ...cell.getCellProps() }  className='whitespace-nowrap px-6 py-4'>
                          {
                            cell.render("Cell")
                          }
                        </td>
                      )
                    }

                  </tr>
                )
              })
            }
          </tbody>

        </table>

        <div className='gap-3'>
          Page{' '}
          <strong>
            {pageIndex + 1 } of {pageOptions.length}
          </strong>
          <span>
            {' | '} Go to page {' '} 
            <input 
              type="number" 
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value ? Number(e.target.value) -1 : 0
                gotoPage(pageNumber)
              }} 
              className='w-10 border-2 bg-black-gradient rounded-md px-1'/>
          </span>

          <select value={pageSize} className="border-2 bg-black mx-2 px-1 py-[1.5px] rounded-lg"
            onChange={e => setPageSize(Number(e.target.value))}
          >
            {
              [3,4,5,10,15,20,25].map(pageSize => (
                <option value={pageSize} key={pageSize}>
                  Show {pageSize}
                </option>
              ))
            }
          </select>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{' << '}</button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage} className='border-2 px-2 py-1 mx-2 my-2 rounded-2xl bg-black'>Previous</button>
          <button onClick={() => nextPage()}  disabled={!canNextPage} className='border-2 px-2 py-1 mx-2 my-2 rounded-2xl bg-black'>Next</button>
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} >{' >> '}</button>
        </div>
      </section>
    </section>
  )
}

export default UserTable
