export const COLUMNS = [
    {
        Header: "Google ID",
        Footer: "Google ID",
        accessor: "googleId"
    },
    {
        Header: "User Email",
        Footer: "Email Address",
        accessor: "email"
    },
    {
        Header: "Full Name",
        Footer: "Name",
        accessor: "name"
    },
    {
        Header: "Role",
        Footer: "Role",
        accessor: "role",
    },
    {
        Header: "Image URL",
        Footer: "ImageUrl",
        accessor: "imageUrl",
        show: false,
    },
    {
        Header: "Status",
        Footer: "Status",
        accessor: "status",
        Cell: ({value}) => value ? 
            <p className="border-2 bg-green-600 text-center p-1 rounded-2xl border-green-300">Active</p> :
            <p className="border-2 bg-red-600 p-1 text-center rounded-2xl border-red-400">Inactive</p>
    },
    {
        Header: "Verified",
        Footer: "Verified",
        accessor: "verified",
        Cell: ({value}) => value ? 
            <p className="border-2 bg-green-600 text-center p-1 rounded-2xl border-green-300">Yes</p> :
            <p className="border-2 bg-red-600 p-1 text-center rounded-2xl border-red-400">No</p>
    },
    
]