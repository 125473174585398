import React from 'react'
import { AiOutlineEdit, AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'

const ContactInfo = ( { data, modal }) => {
  return (
    <div class="bg-black-gradient shadow-lg rounded-md p-4 mt-2 gap-y-3 flex flex-col">
        <div className='flex justify-between p-1'>
        <h2 class="text-md font-semibold">Contact Information</h2>
        <button title='Edit' onClick={modal}><AiOutlineEdit /></button>
        </div>
        
        <hr className='border-1'/>

        {
        data.contactInfo ? (
            <div className='gap-y-3 flex flex-col'>
            <div className='flex justify-between items-center'>
                <p class="mt-2 flex justify-center items-center gap-x-1"><AiOutlineMail />Email Address: </p> 
                <p>_______</p>
                <p class="mt-2">{ data.contactInfo.email }</p>
            </div>
            
            <div className='flex justify-between items-center'>
                <p class="mt-2 flex justify-center items-center gap-x-1"><AiOutlinePhone />Mobile: </p> 
                <p class="mt-2">{  data.contactInfo.mobile }</p>
            </div>

            <div className='flex justify-between items-center'>
                
                <p class="mt-2  flex justify-center items-center gap-x-1"><AiOutlinePhone />Telephone: </p> 
                <p class="mt-2">{  data.contactInfo.telephone }</p>
            </div>

            <div className='flex flex-col flex-wrap'>
                <p class="mt-2 font-semibold">Physical Address </p> 
                <p class="mt-2">"{  data.contactInfo.address }"</p>
            </div>
            </div>
        ) : <p>Contact Information Not Available</p>

        }

        
    </div>
  )
}

export default ContactInfo
