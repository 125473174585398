import React from 'react'
import styles, {layout} from '../styles'
import { useSpring, animated } from 'react-spring';

const About = () => {
  const chainedSpring = useSpring({
    from: { opacity: 0, transform: 'scale(0.5)' },
    to: { opacity: 1, transform: 'scale(1)' },
  });
  
  return (
    <animated.section className={layout.section} style={chainedSpring}>
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>
          We are available {' '}
          <br className="sm:block hidden" />
          in your <span className='text-gradient'>time of need</span>
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-3`}>
          Welcome to our loan website, where we provide access to financing solutions 
          for individuals. We understand that financial needs can 
          arise at any time, which is why we are committed to offering flexible and affordable
          loan options to help you achieve your goals.
        </p>
      </div>
      <div className={`${layout.sectionImg} flex-col`}>
        <div className='flex flex-1 flex-row p-[14px] justify-center gap-2'>
          <div className='absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient'></div>
          <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" className='mt-[20px] md:py-1'>
            <path d="M25.4696 19.7122C25.0552 20.1138 24.8648 20.6946 24.9592 21.2642L26.3816 29.1362C26.5016 29.8034 26.22 30.4786 25.6616 30.8642C25.1144 31.2642 24.3864 31.3122 23.7896 30.9922L16.7032 27.2962C16.4568 27.165 16.1832 27.0946 15.9032 27.0866H15.4696C15.3192 27.109 15.172 27.157 15.0376 27.2306L7.94959 30.9442C7.59919 31.1202 7.20239 31.1826 6.81359 31.1202C5.86639 30.941 5.23439 30.0386 5.38959 29.0866L6.81359 21.2146C6.90799 20.6402 6.71759 20.0562 6.30319 19.6482L0.525591 14.0482C0.042391 13.5794 -0.125609 12.8754 0.095191 12.2402C0.309591 11.6066 0.856791 11.1442 1.51759 11.0402L9.46959 9.88663C10.0744 9.82423 10.6056 9.45623 10.8776 8.91223L14.3816 1.72823C14.4648 1.56823 14.572 1.42103 14.7016 1.29623L14.8456 1.18423C14.9208 1.10103 15.0072 1.03223 15.1032 0.976232L15.2776 0.912232L15.5496 0.800232H16.2232C16.8248 0.862632 17.3544 1.22263 17.6312 1.76023L21.1816 8.91223C21.4376 9.43543 21.9352 9.79863 22.5096 9.88663L30.4616 11.0402C31.1336 11.1362 31.6952 11.6002 31.9176 12.2402C32.1272 12.8818 31.9464 13.5858 31.4536 14.0482L25.4696 19.7122Z" fill="#00F6FF"/>
          </svg>
          <p className={`${styles.paragraph} max-w-[470px] mt-3`}>
            At our loan website, we pride ourselves on being a responsible lender, and we 
            take our commitment to responsible lending seriously. We conduct 
            thorough credit checks and carefully consider each borrower's ability to repay the loan before approving any application.
          </p>
        </div>
        <div className='flex flex-1 '>
          <p className={`${styles.paragraph} max-w-[470px] mt-3 text-gradient`}>
           Thank you for considering our loan website as your lending partner. 
           We look forward to helping you achieve your financial goals.
          </p>
        </div>
      </div>
    </animated.section>
  )
}

export default About
