import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MyApplications from '../MyApplications'
import { AiOutlineArrowLeft , AiTwotoneDelete} from 'react-icons/ai'
import { BiBlock } from 'react-icons/bi'
import UserProfile from '../../containers/UserProfile'
import swal from 'sweetalert'
import axios from 'axios'

const UserInfo = () => {
  const { userObj } = useParams()
  
  const [user, setUser] = useState(JSON.parse(userObj))

  const statusStyle = 'rounded-full p-2 border-2 border-gray-400 cursor-pointer'

  const [activeTab, setActiveTab] = useState(0);

  const navigate = useNavigate()

  // Define tab content
  const tabContent = [
    { title: 'Details', content: <UserProfile googleId={user.googleId}/> },
    { title: 'Pending Loans', content: <MyApplications id={user.googleId}/> },
    { title: 'Active Loans', content: 'Content for Tab 3' },
  ];

  // Handle tab change
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const blockAccount = () => {
    swal({
      icon: "warning",
      title: "Blocking Account",
      text: user.status ? 'We should block this account' : 'We should unblock this account?',
      buttons: ['No', 'Yes'],
      closeModal: false,
      dangerMode: true,
    }).then(confirm => {
      if(confirm){
        axios.post('https://loan-app-server.onrender.com/auth/update/status', { googleId: user.googleId }, {
          headers: {
            loginToken: localStorage.getItem('loginToken')
          }
        }).then(res => {
          if(res.data.message){
            swal({
              icon: "success",
              title: "Update Success",
              text: res.data.message,
              button: false,
              timer: 2000
            })
            setUser({...user, status:!user.status})
          }else if(res.data.error){
             swal({
              icon: "error",
              title: "Error",
              text: res.data.error,
              button: false,
              timer: 3000
            })
          }

        }).catch(err => {
           swal({
              icon: "error",
              title: "Error",
              text: err.message,
              button: false,
              timer: 2000
            })
        })

      }
    })
  }

  const deleteAccount = () => {
    swal({
      icon: "warning",
      title: "Remove Account",
      text: 'We should delete this account ?',
      buttons: ['No', 'Yes'],
      closeModal: false,
      dangerMode: true,
    }).then(confirm => {
      if(confirm){
        navigate(-1)
      }
    })
  }

  return (
    <div className='w-full flex flex-col items-center justify-between relative gap-3'>
      <div 
        className='absolute top-5 left-2 flex md:flex-col flex-row gap-2'
      >
        <div className='animate-pulse rounded-full p-2 border-2 border-gray-400 cursor-pointer'  onClick={() => navigate(-1) } >
          <AiOutlineArrowLeft fontSize={24} className='text-white ' />
        </div>

         <div 
          className= {user.status ? statusStyle + ' bg-green-500 '  : statusStyle + ' bg-red-400' }
          title={ user.status ? "Block this account" : "Unblock this account" }
          onClick={blockAccount}
        >
          <BiBlock fontSize={24} className='text-white' />
        </div>

         <div 
          className='bg-red-600 rounded-full p-2 border-2 border-gray-400 cursor-pointer' 
          title='Delete this account'
          onClick={deleteAccount}
        >
          <AiTwotoneDelete fontSize={24} className='text-white' />
        </div> 
      </div>

      <div className='flex items-center justify-center flex-col m-3  border-2 border-gray-400 rounded-3xl w-full p-3 py-7'>
        <h1 className='text-xl md:mt-1 mt-10 uppercase font-semibold '>{user.role + " : " + user.name}</h1>

        <div className='p-2 flex flex-1 flex-col justify-center'>
          <img src={user.imageUrl} alt="ProfileImage" className='rounded-3xl h-26 w-26' />
        </div>
        <p className='text-lg'>Email : { user.email }</p>
        <div className='flex flex-row justify-between items-center w-[375px] p-3 text-lg'>
          <p>Active ? : { user.status ? "Yes" : "No" }</p>
          <p>Verified ? : { user.verified ? "Yes" : "No" }</p>
        </div>
      </div>

      {/* Render tab buttons */}
      <div className='w-full flex flex-row justify-evenly mt-3'>
        {tabContent.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabChange(index)}
            className={activeTab === index ? 'bg-white text-black p-2 rounded-2xl m-1': 'border-2 border-white rounded-2xl p-3'}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {/* Render active tab content */}
      <div className='w-full p-2'>
        {tabContent[activeTab].content}
      </div>
    </div>
  );
}

export default UserInfo
