import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../helpers/Context'
import { AttachmentInfoEditModal, BasicInfoEditModal, ContactInfoEditModal, WorkInfoEditModal } from "../components/modals/user"
import { AttachmentInfo, BasicInfo, ContactInfo, EmploymentInfo } from '../components'
import swal from 'sweetalert'


const UserProfile = ({googleId}) => {
   const [userInfo, setUserInfo] = useState({})
   const [loading, setLoading] = useState(false)
   const navigate = useNavigate()
   const {authUser} = useContext(AuthContext)

   const [isBasicOpen, setIsBasicOpen] = useState(false)
   const [isAttachmentOpen, setIsAttachmentOpen] = useState(false)
   const [isContactOpen, setIsContactOpen] = useState(false)
   const [isWorkOpen, setIsWorkOpen] = useState(false)

   const [activeTab, setActiveTab] = useState(0)
  

   const changeTab = index => {
    setActiveTab(index)
   }

   useEffect(() => {
    if(authUser.googleId !== 0){
      !authUser.login_status && navigate("/")
    }else{
      if(!localStorage.getItem('loginToken')){
        navigate("/")
      }
    }
   }, [authUser, navigate])

   useEffect(() => {
    setLoading(true)
    axios.get(`https://loan-app-server.onrender.com/basicInfo/checkProfile/profile/${googleId}`, {
      headers: {
        loginToken: localStorage.getItem("loginToken")
      }
    }).then(res => {
      setLoading(false)
      if(!res.data){

        if(authUser.role !== "admin"){
          navigate("/createProfile")
        }else{
          setUserInfo(res.data)
        }
        
      }else{

        setUserInfo(res.data)
      }
    }).catch(err => swal({
      icon: "error",
      title: "Verification Error",
      text: JSON.stringify(err)
    }))
  }, [])

  const openBasicModal = () => {
    setIsBasicOpen(true)
  }

  const closeBasicModal = () => {
    setIsBasicOpen(false)
  }

  const openContactModal = () => {
    setIsContactOpen(true)
  }

  const closeContactModal = () => {
    setIsContactOpen(false)
  }

  const openAttachmentModal = () => {
    setIsAttachmentOpen(true)
  }

  const closeAttachmentModal = () => {
    setIsAttachmentOpen(false)
  }

  const openWorkModal = () => {
    setIsWorkOpen(true)
  }

  const closeWorkModal = () => {
    setIsWorkOpen(false)
  }

   const tabs = [
    {title: 'Contact Info' , content: <ContactInfo data={userInfo} modal={openContactModal}/>},
    {title: 'Work Info' , content: <EmploymentInfo data={userInfo} modal={openWorkModal}/>},
    {title: 'Attachment' , content: <AttachmentInfo data={userInfo} modal={openAttachmentModal} />},
   ]

  return (
    <div className='my-3'>
      {
        loading ? <p className='w-full text-center text-lg text-blue-600'>Loading ...</p> : 
          <div className='w-full flex flex-col items-center justify-center p-2'>
          <h1 className='text-2xl font-light animate-bounce pb-2'>Profile Information</h1>
          <hr className='w-full'/>

          <div className='p-1'>
            {
              userInfo.basicInfo && (
                <div>
                  <BasicInfoEditModal 
                    isOpen={isBasicOpen} 
                    onClose={closeBasicModal} 
                    data={userInfo} 
                    setData={setUserInfo}
                  />
                  <AttachmentInfoEditModal 
                    isOpen={isAttachmentOpen} 
                    onClose={closeAttachmentModal} 
                    data={userInfo} 
                    setData={setUserInfo}
                  />
                  <ContactInfoEditModal 
                    isOpen={isContactOpen} 
                    onClose={closeContactModal} 
                    data={userInfo} 
                    setData={setUserInfo}
                  />
                  <WorkInfoEditModal 
                    isOpen={isWorkOpen} 
                    onClose={closeWorkModal} 
                    data={userInfo} 
                    setData={setUserInfo}
                  />

                  <BasicInfo data={userInfo} modal={openBasicModal}/>
                </div>
            )
          }
          </div>

          <div className='w-full p-3 mt-2 md:flex hidden md:flex-row flex-col flex-wrap md:justify-evenly'>
            <ContactInfo data={userInfo} modal={openContactModal}/>

            <EmploymentInfo data={userInfo} modal={openWorkModal} />

            <AttachmentInfo data={userInfo} modal={openAttachmentModal} />
          </div>
        </div>
      }

    {
      !loading && (
        <div>
          <div className='md:hidden flex flex-1 flex-row items-center justify-evenly p-2 font-semibold'>
            {
              tabs.map((tab, index) => 
                <button 
                  key={index} 
                  onClick={() => changeTab(index)} 
                  className={activeTab === index ? 'bg-white text-black p-2 rounded-2xl m-1': 'border-2 border-white rounded-2xl p-2'}>
                  {tab.title}
                </button>
              )
            }
          </div>

          <div className='md:hidden'>

              {
                tabs[activeTab].content
              }
          </div>
        </div>
      )
    }
    
   
     
    </div>
  )
}

export default UserProfile
