import React, { useState } from 'react'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { NavLink } from 'react-router-dom'

const CollapsibleElement = () => {
  const [expanded, setExpanded] = useState(false)
  const isActiveStyle = 'flex w-full items-center px-5 gap-4 font-bold border-r-2 border-white text-white font-extrabold transaction-all duration-200 ease-in-out hover:shadow-xl py-1'
  const isNotActiveStyle = 'flex items-center px-5 py-1 gap-2 text-white hover:font-extrabold transaction-all duration-200 ease-in-out capitalize hover:shadow-xl py-1'
  

  const handleExpanded = () => {
    setExpanded(!expanded)
  }

  return (
    <div onClick={handleExpanded} className='flex flex-col'>
        <div className='flex flex-row justify-between items-center px-5 py-2 text-white font-bold'>
            <button>Admin Roles</button>
            <span>
                {
                    !expanded ? <AiFillCaretDown className='text-white' fontSize={20}/> : <AiFillCaretUp className='text-white' fontSize={20}/>
                }
            </span>
        </div>

        {expanded && (
            <div className='p-2 border-2 border-x-gray-600 border-y-black rounded-xl mx-4'>
                <NavLink
                    to={"loanUsers"}
                    className={({isActive}) => isActive ? isActiveStyle : isNotActiveStyle}
                    key="Loan Users"
                    onClick={() => handleExpanded}
                >
                     - Loan Users
                </NavLink>

                <NavLink
                    to={"pendingApplications"}
                    className={({isActive}) => isActive ? isActiveStyle : isNotActiveStyle}
                    key="Lined Applications"
                    onClick={handleExpanded}
                >
                    - Lined Applications
                </NavLink>
            </div>
        )}

        
        </div>
  )
}

export default CollapsibleElement
