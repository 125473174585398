import React, { useEffect, useContext, useState } from 'react'
import loanVideo from "../assets/loanVideo.mp4"
import logo from "../assets/logoweb.webp"
import { FcGoogle } from "react-icons/fc";
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import axios from "axios";
import swal from "sweetalert"
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../helpers/Context';
import { AiFillHome, AiOutlineMail } from 'react-icons/ai';
import EmailRegisterForm from './EmailRegisterForm';

const Register = () => {
  const clientId = process.env.REACT_APP_GOOGLE_API_TOKEN
  const navigate = useNavigate()
  const { authUser } = useContext(AuthContext)
  const [toggleEmailRegister, setToggleEmailRegister] = useState(false)

  useEffect(() => {
    if(authUser.googleId !== 0){
      authUser.login_status && navigate("/")
    }
  }, [authUser])

  const controlEmailRegister = () => {
    setToggleEmailRegister(prev => 
      !prev ? true : false
    )
  }

  const goToHome = () => {
    navigate('/')
  }

  const onSuccess = (res) => {
    const data = res.profileObj;
    data.loginType = "google"
    
    axios.post("https://loan-app-server.onrender.com/emailUser/register", {...data, password:"none", verified:true})
      .then(res => {
       if(!res.data.error){
        swal({
          icon: "success",
          title: "User Registered",
          text: "Registration Successful- You are being redirected to login page",
          timer: 3000
        }).then(() => {
          navigate("/login")
        })
       }else{
        swal({
          icon: "error",
          title: "Oops",
          text: res.data.error,
           timer: 4000
        }).then(() => {
          navigate("/login")
        })
       }
      })
      .catch(err => {
        console.log("failed", err)
      })
  };
  const onFailure = (err) => {
      console.log('failed:', err);
  };

  useEffect(() => {
    const initClient = () => {
          gapi.client.init({
          clientId: clientId,
          scope: ''
        });
     };
     gapi.load('client:auth2', initClient);
 }, []);

  return (
    <div className="flex flex-start items-center flex-col font-poppins h-screen ">
      <div className='relative h-screen w-full'>
        <video
            src={loanVideo}
            type="video/mp4"
            className='h-screen object-cover w-full '
            autoPlay={true}
            loop
            controls={false}
            muted
        />
      </div>
      <div className='absolute left-0 top-0 p-[12px] rounded-full bg-green-700 m-1 animate-pulse cursor-pointer z-10' onClick={goToHome}>
        <AiFillHome fontSize={20} className='text-white' />
      </div>
      <div  className="absolute left-0 overflow-auto right-0 flex flex-col py-4 items-center bg-black bg-opacity-40 w-full h-screen gap-y-[12px]">
        <div className="p-1">
          <img src={logo} alt="logo" width={200}/>
        </div>
       
        <div className="shadow-2xl">
          <GoogleLogin 
            clientId={clientId}
            render={(props) => (
              <button
                type='button'
                className='bg-white flex justify-center items-center p-2 rounded-lg shadow-lg cursor-pointer outline-none'
                onClick={props.onClick}
                disabled={props.disabled}
              >
                <FcGoogle className='mr-4'/> Register with Google
              </button>
            )}
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
          />
        </div>

        {
          !toggleEmailRegister && (
            <div className="shadow-2xl">
              <button
                type='button'
                onClick={controlEmailRegister}
                className='bg-white flex justify-center items-center p-2 rounded-lg shadow-lg cursor-pointer outline-none px-[15px]'
              >
                <AiOutlineMail className='mr-4'/> Register with Email
              </button>
            </div>
          )
        }

        {
          toggleEmailRegister && <EmailRegisterForm />
        }

        <div className='text-white font-bold'>
          <Link to={'/login'}>Already have an account? -Login </Link>
        </div>
        
      </div>

    </div>
  )
}

export default Register
