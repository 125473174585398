import React from 'react'
import styles from '../styles'
import logo from '../assets/logoweb.webp'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  const isActiveStyle = 'flex items-center px-5 font-bold border-b-2 border-white transaction-all duration-200 ease-in-out hover:shadow-xl py-1'
  const isNotActiveStyle = 'flex items-center hover:text-gray-400 transaction-all duration-200 ease-in-out capitalize hover:shadow-xl py-1'
  
  return (
    <div className={`${styles.flexCenter}  ${styles.paddingY} border-t-2 border-white clear`}>
        <div className={` flex flex-col md:flex-row mb-8 w-full`}>
            <div className='flex flex-1 flex-col justify-start mr-10 relative'>
              <div className='first-letter:z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient'></div>
                <div className='z-[1] w-[80%] h-[85%] rounded-full bottom-40 white__gradient'></div>
                <img src={logo} alt="LOGO" className='object-contain w-[266px] h-[76px]'/>
                <p className={`${styles.paragraph} max-w-[310px] mt-4`}>
                    Empowering your dreams with flexible loans.
                </p>
            </div>
            <div className="flex flex-[1.5] flex-row justify-between flex-wrap md:mt-0 mt-10 gap-y-4">
               <div className='flex flex-col items-start'>
                <h1 className='text-lg font-semibold font-poppins mb-[5px]'>Common Links</h1>
                <ul className='flex flex-col gap-y-2'>
                   <NavLink
                      to={"/what"}
                      className = {({isActive}) => isActive ? isActiveStyle : isNotActiveStyle }
                    >
                      What ?
                    </NavLink>

                    <NavLink
                      to ="/who"
                      className = {({isActive}) => isActive ? isActiveStyle : isNotActiveStyle }
                    >
                      Who ?
                    </NavLink>

                    <NavLink
                      to={"/rates"}
                      className = {({isActive}) => isActive ? isActiveStyle : isNotActiveStyle }
                    >
                      Rates
                    </NavLink>

                    <NavLink
                      to={"contact"}
                      className = {({isActive}) => isActive ? isActiveStyle : isNotActiveStyle }
                    >
                      Contact Us
                    </NavLink>
                </ul>
               </div>

               <div className='flex flex-col items-center'>
                <h1 className='text-lg font-semibold font-poppins mb-[5px]'>Offers</h1>
                <ul className='flex flex-col items-center gap-y-2'>
                  <li>1 Week - 20%</li>
                  <li>2 Weeks - 30%</li>
                  <li>3 Weeks - 40%</li>
                  <li>4 Weeks - 50%</li>
                </ul>
               </div>

              <div className='flex flex-col items-end'>
                <h1 className='text-lg font-semibold font-poppins mb-[5px]'>Reach Us</h1>
                <ul className='flex flex-col items-end gap-y-2'>
                  <li>+265 996 570 252</li>
                  <li>+265 884 799 203</li>
                  <li>mikelibamba@gmail.com</li>
                  <li>bis16-mlibamba@poly.ac.mw</li>
                </ul>
              </div>
            </div>
        </div>
        
    </div>
  )
}

export default Footer
