import React, { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import axios from 'axios'
import { AuthContext } from '../../../helpers/Context.js'
import AttachmentsForm from './AttachmentsForm'
import Loading from '../../Loading'
import swal from 'sweetalert'
  
const Attachments = ({nextStep, prevStep}) => {
  const {authUser} = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [initValues, setInitValues] = useState({
    fileName : "",
    file: ""
  })


  useEffect(() => {
    setLoading(true)
    axios.get(`https://loan-app-server.onrender.com/attachmentInfo/${authUser.googleId}`, {
      headers: {
        loginToken: localStorage.getItem('loginToken')
      }
    }).then(res => {
      setLoading(false)
      if(!res.data.error)
      {
        setInitValues(res.data)
        setImage(res.data.location)
      }else{
        setInitValues(initValues)
      }
      
    })
    .catch(err => {
      setLoading(false)
      console.log(err.message)
    })
  }, [])
  
  const validationSchema = Yup.object().shape({
    fileName: Yup.string()
      .required("Please enter the name of the file"),
    file: Yup.mixed().required('Attach an image of your identity'),
  })

  const onSubmit = data => {

    const formData = {
      fileName: data.fileName,
      location: data.file
    }

    setLoading(true)
    axios.post("https://loan-app-server.onrender.com/attachmentInfo", formData, {
      headers: {
        loginToken: localStorage.getItem("loginToken"),
      }
    }).then(res => {
      console.log(res)
      setLoading(false)
      nextStep()
    }).catch(err => {
      setLoading(false)
      swal({
        icon:"error",
        title:"Oops!",
        text:err.response.data.error,
      })
    })
    
  }
  
  return (
    <div className='w-full'>
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
      
       <AttachmentsForm initValues={initValues} prevStep={prevStep} image={image} />
        
      </Formik>

      {
        loading && <Loading />
      }
    </div>
  )
}

export default Attachments
