import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
import swal from 'sweetalert'
import * as Yup from 'yup'
import logo from "../assets/logoweb.webp"

const ResetPasswordForm = () => {
  const { userId, token } = useParams()  
  const navigate = useNavigate()
  const initValues= {
    password: "",
    password2: ""
  } 

  const [loading, setLoading] = useState(false)

  const gameOver = useSpring({
    from:{ opacity: 0, marginTop: -1000 },
    to:{ opacity: 1, marginTop: 0 }
  })

  const validationSchema = Yup.object().shape({
    password: Yup
        .string()
        .required('Please Enter your password')
        .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    password2: Yup
        .string()
        .oneOf([Yup.ref('password'), null], "Passwords don't match!")
        .required('Required'),      
  })

  const onSubmit = (data, { resetForm }) => {
    setLoading(true)

    const dataSubmitted = {
        userId: userId,
        password: data.password,
        token: token
    }

    axios.post("https://loan-app-server.onrender.com/emailUser/password/reset", dataSubmitted)
    .then(result => {
        setLoading(false)
        if(!result.data.error){
            resetForm()
            swal({
                icon:"success",
                title:"Success",
                text:`Password Updated`,
                timer: 3000
            }).then(result => {
                navigate('/login')
            })
        }else if(result.message){
             swal({
                icon:"success",
                title:"Email Sent!",
                text:result.data.message,
                buttons: true
            })
        }else{
            swal({
                icon:"error",
                title:"Oops!",
                text:result.data.error,
                buttons: true
            })
        }
    })
    .catch(err => {
        setLoading(false)
        console.log(err)

        if(err.response){
            swal({
                icon:"error",
                title:"Oops!",
                text:err.response.data.error,
            })
        }else{
            swal({
                icon:"error",
                title:"Oops!",
                text:err.message,
            })
        }
       
    })
  }

  return (
    <animated.div style={gameOver} className='mt-[10px] w-full font-poppins flex flex-col items-center justify-center px-5 h-screen bg-primary'>
        <img src={logo} alt="LoGO" className='w-60'/>
    <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
 
        {({ errors, touched, dirty, isValid }) => (
            <Form className='flex flex-col w-full sm:max-w-[365px] ss:max-w-[365px] md:max-w-lg  gap-y-2'>
                <div className='flex flex-col gap-3 shadow-xl max-w-2xl py-6 px-2 border-y-2 border-y-green-500 rounded-2xl'>
                    <div className='flex flex-col flex-1 items-start p-2 gap-y-4' >
                        <div className="flex flex-col items-start gap-y-1 w-full">
                            <label htmlFor=""
                                className="text-lg px-2 font-semibold font-poppins text-white"
                            >
                                New Password* :
                            </label>
                            <div className='flex w-full animate-pulse flex-row items-end text-gradient font-medium justify-end px-2'>
                                <ErrorMessage name='password'/>
                            </div>
                            <Field
                                name="password"
                                id="password"
                                type='password'
                                className={touched.password && errors.password ? 
                                "w-full p-3 rounded-lg px-5 text-red-800 border-2 border-red-700" :
                                "w-full p-3 rounded-lg px-5 text-black"}
                                autoComplete="off"
                            />
                        </div>

                        <div className="flex flex-col items-start gap-y-1 w-full">
                            <label htmlFor=""
                                className="text-lg px-2 font-semibold font-poppins text-white"
                            >
                                Re-type password* :
                            </label>
                            <div className='flex w-full animate-pulse flex-row items-end text-gradient font-medium justify-end px-2'>
                                <ErrorMessage name='password2'/>
                            </div>
                            <Field
                                name="password2"
                                id="password2"
                                type='password'
                                className={touched.password2 && errors.password2 ? 
                                "w-full p-3 rounded-lg px-5 text-red-800 border-2 border-red-700" :
                                "w-full p-3 rounded-lg px-5 text-black"}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
                
                <div className='w-full flex flex-col items-end justify-center'>
                    <button 
                        type='submit'
                        className={
                        !(dirty && isValid) ? 
                        "border-0 p-2 bg-white bg-opacity-80 text-gray-400 rounded-xl px-5"
                        : 'border-0 p-3 bg-white bg-opacity-60 text-green-900 rounded-xl px-5'
                        }>
                            {
                                loading ? "Updating... " : "Update"
                            } 
                    </button>
                </div> 
            </Form>
        )}
           
      </Formik> 
    </animated.div> 
  )
}

export default ResetPasswordForm
