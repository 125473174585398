import axios from 'axios';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState } from 'react';
import swal from 'sweetalert'
import { storage } from '../../../firebase';
import { v4 } from 'uuid';

function BankInfoEditModal({ isOpen, onClose, data, setData, loanID, }) {
  const overlayClass = isOpen ? 'fixed inset-0 bg-gray-500 opacity-75 z-50' : 'hidden';
  const modalClass = isOpen ? 'fixed inset-0 flex items-center justify-center z-50' : 'hidden';
  const formClass = "border-2 border-x-gray-500 p-1 rounded-md";
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState(null)
  const [imageLink, setImageLink] = useState(null)
  const [attachmentUploadStatus, setAttachmentUploadStatus] = useState(0)

  const [formData, setFormData] = useState({
    fileName: data.attachmentInfo ? data.attachmentInfo.fileName : null,
    file: data.attachmentInfo ? data.attachmentInfo.file : null,
  })

  const handleFileChange = (e) => {
    const _file = e.target.files[0]
    const MAX_FILE_SIZE = 2048

    const fileSizeKiloBytes = _file.size / 1024

    if (!_file) {
      swal({
        icon: "warning",
        title: "Oops!",
        text:"Please Select a file",
        buttons: true
      })
      setPreview(null)
      return
    }


    if(fileSizeKiloBytes > MAX_FILE_SIZE){
      swal({
        icon: "warning",
        title: "Oops!",
        text:"File is too large. Maximum: 2MB",
        buttons: true
      })
      setPreview(null)
      return
    }

    try{
        setPreview(_file)
        const storageRef = ref(storage, `/attachments/${_file.name + v4()}`)
        const uploadTask = uploadBytesResumable(storageRef, _file)

        uploadTask.on(
            "state_changed",
            snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                setAttachmentUploadStatus(progress)
            },
            err => console.log(err.message),
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
              .then(url => {
                setFormData({...formData, file: url})
                setImageLink(url)
              })
            }
        )
    }catch(err){
      console.log(err)
    }
  }


  const editInfo = () => {
    if(preview){
      setLoading(true)

      const _formData = {
        fileName: formData.fileName,
        location: formData.file
      }

      axios.post("https://loan-app-server.onrender.com/attachmentInfo", _formData, {
        headers: {
          loginToken: localStorage.getItem("loginToken")
        }
      }).then(result => {
        //get the generated loan id from auto increment
        setData({...data,
          attachmentInfo:{...data.attachmentInfo,
            fileName: formData.fileName,
            location: formData.file,
          }
        })
        setLoading(false)
        onClose()
        swal({
          icon:"success",
          title:"Update",
          text:"Attachment Details Updated Successfully!",
          buttons:false,
          timer: "2000"
        })
      }).catch(err => {
        setLoading(false)
        swal({
          icon:"error",
          title:"Oops!",
          text:err.response.data.error,
        })
      })
    }else{
      swal({
        icon: "warning",
        title: "Oops!",
        text:"Please Select a file and maximum:2MB",
        buttons: true
      })
    }
    
  }

  return (
    <div className='text-black'>
      <div className={overlayClass} onClick={onClose}></div>

      <div className={modalClass}>
        <div className="bg-white rounded-lg overflow-hidden md:w-1/4 w-2/3">
          <div className="p-4">
            <button className="float-right text-gray-500" onClick={onClose}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            <h2 className='text-lg font-semibold p-2'>Updating Attachment Information</h2>
            <hr />

            <div className='p-2 flex flex-col'>
              <label htmlFor="" >Attachment File</label>
              <input type="file" accept='image/*' className={formClass}
                onChange={handleFileChange} 
              />
              <span className='animate-pulse'>Upload Status: {attachmentUploadStatus}%</span>

              <label htmlFor="" className='mt-4'>Attachment Name</label>
              <input type="text" value={formData.fileName} className={formClass}
                onChange={e => setFormData({...formData, fileName:e.target.value})}
                placeholder='e.g. National ID, Driving License, etc.' 
              />

              {
                imageLink &&
                  <button 
                    className='w-full mt-3 border-2 p-2 bg-green-600 text-white rounded-lg'
                    onClick={() => editInfo()}
                  >
                    {loading ? "Updating ...": "Update"}
                  </button>
              }
              

            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}
export default BankInfoEditModal;