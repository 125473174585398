import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import checkMark from '../assets/checkmark.gif'
import logo from '../assets/logoweb.webp'
import axios from 'axios'
import swal from 'sweetalert'

const EmailVerify = () => {
  const {userId, token} = useParams() 
  const [loading, setLoading] = useState(true)
  const [trackErrors, setTrackErrors] = useState(false)

  useEffect(() => {
    axios.post("https://loan-app-server.onrender.com/emailUser/verify", {userId: userId, token: token})
    .then(result => {
        setLoading(false)
        if(result.data.error){
          setTrackErrors(true)
          swal({
            icon:"error",
            title:"Oops!",
            text:result.data.error,
          })
        }
    })
    .catch(err => {
      setLoading(false)
      setTrackErrors(true)
      swal({
        icon:"error",
        title:"Oops!",
        text:err.response.data.error,
      })
    })
  }, [])

  return (
    <div className='flex items-center justify-center flex-col w-full h-[100vh] bg-black bg-opacity-20'>
      <img src={logo} alt='VerifiedImage' className='w-60'/>

      {
        loading ? <p className='text-lg italic'>Verifying...</p> : (
          
          <div className='flex flex-col items-center justify-center'>

            {
              !trackErrors && (
                <>
                  <h1 className='font-poppins text-[16pt] font-semibold'>Email Verified</h1>
                  <img src={checkMark} alt='VerifiedImage' className='w-44'/>
                  <Link to={'/login'} className='m-3 border-2 rounded-lg border-gray-500 px-5 py-1 animate-pulse'>
                    Login
                  </Link>
                </>
              ) 
            }

            <Link to={'/'} className='m-3 border-2 rounded-lg border-gray-500 px-5 py-1 animate-pulse'>
              Back to Home page
            </Link>
           
          </div>
        )
      }
     
    </div>
  )
}

export default EmailVerify
