// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage'


const firebaseConfig = {
  apiKey: "AIzaSyDwX_NRgPmLB0K4C0FIgeJH3EddxgLKBVw",
  authDomain: "loan-files-c7458.firebaseapp.com",
  projectId: "loan-files-c7458",
  storageBucket: "loan-files-c7458.appspot.com",
  messagingSenderId: "656519488823",
  appId: "1:656519488823:web:e9ada0ac9c133abf6ce6b2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)