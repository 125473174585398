import React from 'react'
import styles from '../styles'
import { AiOutlineArrowUp } from 'react-icons/ai'

const GetStarted = () => (
    <div className={`${styles.flexCenter} w-[100px] h-[100px] p-[2px] cursor-pointer rounded-full bg-blue-gradient`}>
        <div className={`${styles.flexCenter} flex-col bg-primary w-[100%] h-[100%] rounded-full`}>
            <div className={`${styles.flexStart} flex-row`}>
                <p className='font-poppins font-medium mr-1 text-[16px] leading-[20px]'>
                    <span className='text-gradient'>Get</span>
                </p>
                <AiOutlineArrowUp fontSize={20} className='object-contain'/>
            </div>
            <p className='font-poppins font-medium text-[16px] leading-[20px]'>
                <span className='text-gradient'>Started</span>
            </p>
        </div>
    </div>
)

export default GetStarted
