import React, { useEffect, useContext, useState } from 'react'
import loanVideo from "../assets/loanVideo.mp4"
import logo from "../assets/logoweb.webp"
import { FcGoogle, } from "react-icons/fc";
import { AiFillHome, AiOutlineMail } from 'react-icons/ai'
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../helpers/Context';
import EmailLogin from './EmailLogin';

const Login = () => {
  const clientId = process.env.REACT_APP_GOOGLE_API_TOKEN
  const navigate = useNavigate()
  const { authUser, setAuthUser } = useContext(AuthContext)
  const [toggleEmailLogin, setToggleEmailLogin] = useState(false)

  useEffect(() => {
    if(authUser.googleId !== 0){
      authUser.login_status && navigate("/")
    }
  }, [authUser])

  const goToHome = () => {
    navigate('/')
  }

  const controlEmailLogin = () => {
    setToggleEmailLogin(prev => 
      !prev ? true : false
    )
  }

  const onSuccess = (res) => {
    const data = res.profileObj
    axios.post(`https://loan-app-server.onrender.com/auth/login/${data.googleId}`, {email: data.email, password: "none"})
      .then(res => {
        if(res.data.error){
          swal({
            title: res.data.error,
            text: "Use email login or register first",
            icon: "warning",
            button: true,
          })
        }else{
          localStorage.setItem("loginToken", res.data)
          
          axios.get("https://loan-app-server.onrender.com/auth/verify", {
            headers: {
              loginToken: localStorage.getItem("loginToken")
            }
          }).then(res => {
            setAuthUser(authUser => ({...authUser, 
              googleId: res.data.user.googleId,
              name: res.data.user.name,
              imageUrl: res.data.user.imageUrl,
              email: res.data.user.email,
              role: res.data.user.role,
              login_status: res.data.login_status 
            }))
            navigate("/")  
          }).catch(err => {
            swal({
              title: "Unverified User",
              text: "Check your email inbox to activate this account",
              icon: "error",
              button: true,
          })
          })
           
        }
      })
    
  };

  const onFailure = (err) => {
      console.log('failed:', err);
  };

  useEffect(() => {
    const initClient = () => {
          gapi.client.init({
          clientId: clientId,
          scope: ''
        });
     };
     gapi.load('client:auth2', initClient);
 }, []);

  return (
    <div className="flex flex-start items-center flex-col h-screen font-poppins">
      <div className='relative h-full w-full'>
        <video
            src={loanVideo}
            type="video/mp4"
            className='h-full object-cover w-full '
            autoPlay={true}
            loop
            controls={false}
            muted
        />
      </div>
      <div className='absolute left-0 top-0 p-[12px] rounded-full bg-green-700 m-1 animate-pulse cursor-pointer z-10' onClick={goToHome}>
        <AiFillHome fontSize={20} className='text-white' />
      </div>
      <div className="absolute left-0 right-0 flex flex-col py-4 items-center bg-black bg-opacity-40 w-full h-screen gap-y-[12px]">
        <div className="p-5">
          <img src={logo} alt="logo" width={200}/>
        </div>
        <div className="shadow-2xl">
          <GoogleLogin 
            clientId={clientId}
            render={(props) => (
              <button
                type='button'
                className='bg-white flex justify-center items-center p-2 rounded-lg shadow-lg cursor-pointer outline-none'
                onClick={props.onClick}
                disabled={props.disabled}
              >
                <FcGoogle className='mr-4'/> Login with Google
              </button>
            )}
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
          />
        </div>

        {
          !toggleEmailLogin && (
            <div className="shadow-2xl">
              <button
                type='button'
                onClick={controlEmailLogin}
                className='bg-white flex justify-center items-center p-2 rounded-lg shadow-lg cursor-pointer outline-none px-[15px]'
              >
                <AiOutlineMail className='mr-4'/> Login with Email
              </button>
            </div>
          )
        }
          
        {
          toggleEmailLogin && <EmailLogin />
        }

        <div className='text-white font-bold'>
          <Link to={'/register'}>Don't have an account? -Register </Link>
        </div>

        <div className='text-white font-bold'>
          <Link to={'/resetPassword'}>Forgot Password?</Link>
        </div>
          
      </div>

    </div>
  )
}

export default Login
