import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React, { useContext, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import axios from 'axios'
import swal from 'sweetalert'
import { AuthContext } from '../helpers/Context'
import { useNavigate } from 'react-router-dom'

const EmailLogin = () => {
  const navigate = useNavigate()
  const initValues= {
    email : "",
    password: ""
  }
  const { setAuthUser } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)

  const gameOver = useSpring({
    from:{ opacity: 0, marginTop: -1000 },
    to:{ opacity: 1, marginTop: 0 }
  })
  

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter your email!"),
    password: Yup.string()
      .required("Enter password")
  })

  const onSubmit = (data, { resetForm }) => {
     setLoading(true)

     axios.post(`https://loan-app-server.onrender.com/auth/login/${data.email}`, ({email: data.email, password: data.password}))
      .then(res => {
        setLoading(false)
        if(res.data.error){
          swal({
            title: "Oops !",
            text: res.data.error,
            icon: "warning",
            button: true,
          })
        }else if(res.data.message){
          swal({
            title: "Email Sent",
            text: res.data.message,
            icon: "success",
            button: true,
          })
        }else{
          resetForm()
          setLoading(false)
          localStorage.setItem("loginToken", res.data)
          
          axios.get("https://loan-app-server.onrender.com/auth/verify", {
            headers: {
              loginToken: localStorage.getItem("loginToken")
            }
          }).then(res => {
            setAuthUser(authUser => ({...authUser, 
              googleId: res.data.user.googleId,
              name: res.data.user.name,
              imageUrl: res.data.user.imageUrl,
              email: res.data.user.email,
              role: res.data.user.role,
              login_status: res.data.login_status 
            }))
            navigate("/")  
          }).catch(err => {
            console.log("verification_error", err)
            setLoading(false)
           })
           
        }
      })
    
  }

  return (
    <animated.div style={gameOver} className='mt-[10px] w-full flex items-center justify-center font-poppins'>
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >

        {({ errors, touched, dirty, isValid }) => (
            <Form className='flex flex-col gap-3 shadow-xl w-full max-w-[365px] py-6  px-2 border-y-2 border-y-green-500 rounded-2xl'>
            <div className='gap-1 flex flex-col items-start p-2' >
                <label htmlFor=""
                    className="text-lg px-2 font-semibold font-poppins text-white"
                >
                    Email Address* :
                </label>
                <div className='flex w-full animate-pulse flex-row items-end text-red-900 font-medium justify-end px-2'>
                    <ErrorMessage name='email'/>
                </div>
                <Field
                    type="email"
                    id="email" 
                    name="email"
                    className={touched.email && errors.email ? 
                    "w-full p-3 rounded-lg px-5 text-red-800 border-2 border-red-700" :
                    "w-full p-3 rounded-lg px-5 text-black"}
                    autoComplete="off"
                />
            </div>

           <div className='gap-1 flex flex-col items-start p-2' >
                <label htmlFor=""
                    className="text-lg px-2 font-semibold font-poppins text-white"
                >
                    Password* :
                </label>
                <div className='flex w-full animate-pulse flex-row items-end text-red-900 font-medium justify-end px-2'>
                    <ErrorMessage name='password'/>
                </div>
                <Field
                    name="password"
                    id="password"
                    type='password'
                    className={touched.password && errors.password ? 
                    "w-full p-3 rounded-lg px-5 text-red-800 border-2 border-red-700" :
                    "w-full p-3 rounded-lg px-5 text-black"}
                    autoComplete="off"
                />
            </div>
            <hr />

            <div className='w-full flex flex-col items-end justify-center'>
                <button 
                    type='submit'
                    className={
                    !(dirty && isValid) ? 
                    "border-0 p-2 bg-white bg-opacity-80 text-gray-400 rounded-xl px-5"
                    : 'border-0 p-3 bg-white bg-opacity-60 text-green-900 rounded-xl px-5'
                    }>

                      {
                        loading ? 'Authenticating...' : 'Login'
                      }
                   
                </button>
            </div> 
            </Form>
        )}
           
      </Formik> 
    </animated.div>
  )
}

export default EmailLogin
