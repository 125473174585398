import React, { useContext, useEffect, useState } from 'react'
import { 
  SideBar, 
  UserInfoPopUp, 
  NavBar, 
  LoanApplication, 
  MyApplications, 
  MyLoans, 
  Rates, 
  About,
  ContactUs,
  Footer,
  PendingApplications,
  LoanUsers,
  UserInfo
} from '../components'
import UserProfile from '../containers/UserProfile'
import logo from "../assets/logoweb.webp"
import { AiOutlineMenu, AiFillCloseCircle } from "react-icons/ai";
import { AuthContext, LoanContext } from '../helpers/Context';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import { Welcome, } from './';
import axios from 'axios';
import LoanDetails from '../components/LoanDetails';
import styles from '../styles'

const Home = () => {
  const { authUser ,setAuthUser, toggleUserInfo, setToggleUserInfo } = useContext(AuthContext)
  const [toggleSidebar, setToggleSidebar] = useState(false)
  const navigate = useNavigate()
  const [loanId, setLoanId] = useState(0)

  useEffect(() => {
    axios.get("https://loan-app-server.onrender.com/basicInfo/checkProfile/profile", {
      headers: {
        loginToken: localStorage.getItem("loginToken")
      }
    }).then(res => {
      if(!res.data){
        navigate("/createProfile")
      }
    }).catch(err => console.log("verification_error", err))
  }, [navigate])

  const imgClicked = () => {
    if(toggleUserInfo){
      setToggleUserInfo(false)
    }else{
      setToggleUserInfo(true)
    }
  }

  return (
    //set loan context values
    <LoanContext.Provider value = {{ loanId, setLoanId }}>
        <div 
          className='flex md:flex-row flex-col w-full h-full bg-primary'
        >
          {/*on desktop show the side nav*/}
          <div className="md:flex hidden w-60 z-30 relative">
            <SideBar />
          </div>

          {/*mobile navbar */}
          <div className='md:hidden w-full flex flex-row p-1'>
            <div className='flex relative p-2 my-1 justify-between items-center shadow-md bg-black-gradient w-full'>
              <div className='p-2'>
                <AiOutlineMenu
                  fontSize={24}
                  className = "cursor-pointer text-white"
                  onClick={ () => setToggleSidebar(true) }
                />
              </div>
              <div className='p-2'>
                <img src={logo} alt="LOGO" className='w-36' />
              </div>
              <div className='p-2'>
                {
                  authUser.login_status ? (
                    <img 
                      src={authUser.imageUrl} alt="PROFILE"
                      className='w-14 rounded-full cursor-pointer'
                      onClick={imgClicked}
                    />
                  ): (
                    <div className='flex justify-center items-center text-white'>
                      <Link to="/login" className='p-3'>Login</Link>
                      <Link to="/register">Register</Link>
                    </div>
                  )
                }
              
              </div>
              {
                toggleUserInfo && (
                  <div className='absolute top-24 right-0  z-[5]'>
                    <UserInfoPopUp />
                  </div>
                )
              }
            </div>
          </div>

          {/* when menu icon is clicked on mobile */}
          {
            toggleSidebar && (
              <div className='fixed w-4/5 h-full bg-black-gradient shadow-5xl md:hidden z-10 transition duration-200 animate-slide-in'>
                <div className='flex flex-row justify-end p-2'>
                  <AiFillCloseCircle
                    fontSize={24}
                    onClick={() => setToggleSidebar(false)}
                    className='text-white'
                  />
                </div>
                <SideBar closeSideBar = {setToggleSidebar}/>
              </div>
            )
          }

          {/* Horizontal navigation  */}
          <div className={`w-full rounded-xl`}>
            <div className='md:px-10'>
              <div className='bg-black-gradient mx-[5px] my-[3px] rounded-lg'>
                <NavBar />
              </div>   
            </div>
           
            <div className={`${styles.boxWidth} ${styles.paddingX} text-white`}>
              <Routes>
                <Route path='/who' element={<About/>}/>
                <Route path='/rates' element={<Rates/>}/>
                <Route path='/contact' element={<ContactUs/>}/>
                <Route path='/profile' element={<UserProfile googleId={authUser.googleId}/>}/>
                <Route path='/newApplication' element={<LoanApplication/>}/>
                <Route path='/myApplications' element={<MyApplications id={authUser.googleId}/>}/>
                <Route path='/loanDetails/:id' element={<LoanDetails/>}/>
                <Route path='/myLoans' element={<MyLoans/>}/>
                <Route path='/loanUsers' element={<LoanUsers/>}/>
                <Route path='/pendingApplications' element={<PendingApplications/>}/>
                <Route path='/user/userInfo/:userObj' element={ <UserInfo/>} />
                <Route path="/*" element={<Welcome/>}/>
              </Routes>
              <Footer />
            </div>
             
           
          </div>  
        </div>

    </LoanContext.Provider>
  )
}

export default Home
