import React from 'react'
import { AiOutlineEdit } from 'react-icons/ai'

const AttachmentInfo = ({data, modal})=> {
  return (
    <div class="bg-black-gradient shadow-lg rounded-md p-4 mt-2 gap-y-3 flex flex-col">
        <div className='flex justify-between p-1'>
        <h2 class="text-md font-semibold">Attachment Details</h2>
        <button title='Edit' onClick={modal}><AiOutlineEdit /></button>
        </div>
        
        <hr className='border-1'/>

        {
        data.attachmentInfo ? (
            <div className='gap-y-3 flex flex-col'>
            <div className='flex justify-between items-center'>
                <p class="mt-2">Attachment Name: </p> 
                <p>_______</p>
                <p class="mt-2">{ data.attachmentInfo.fileName }</p>
            </div>
            
            <div className='flex flex-col flex-wrap'>
                <p class="mt-2">Attachment </p> 
                <img 
                src={data.attachmentInfo.location}
                alt="Attachment File" 
                className='object-contain mt-2 rounded-2xl'
                />
            </div>
            </div>
        ) : <p>Attachment Information Not Available</p>

        }
    </div>
  )
}

export default AttachmentInfo
